import { CustomFlowbiteTheme, Flowbite } from "flowbite-react";
import ReactDOM from "react-dom/client";
import {
  LoaderFunctionArgs,
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import App from "./App.tsx";
import ErrorPage from "./components/error-page.tsx";
import LoginPage from "./components/login/login-page.tsx";
import { PricingPage } from "./components/price/price_page.tsx";
import ProfilePage from "./components/profile/profile.tsx";
import "./index.css";
import { useAuthenticationStore } from "./model/store.ts";
import { SENTRY_ENVIRONMENT, notify } from "./utils/contants.ts";
import { Loader } from "./components/loader.tsx";
import { ToastContainer } from "react-toastify";
import { PrivacyPolicy } from "./components/legal/privacy-policy.tsx";
import { LegalLicense } from "./components/legal/license.tsx";
import { TermsAndConditon } from "./components/legal/term-of-service.tsx";
import PaymentSuccessPage from "./components/payment-success.tsx";
import * as Sentry from "@sentry/react";
import { getOrSetCorrelationId } from "./utils/utils.ts";

async function loginLoader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const code = url.searchParams.get("code");
  const state = url.searchParams.get("state");
  const error = url.searchParams.get("error");
  return { code, state, error };
}

// eslint-disable-next-line no-empty-pattern
async function genericLoader({}: LoaderFunctionArgs) {
  // A generic loader, useful to try get user information if possible
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  await useAuthenticationStore.getState().getAPIUser();

  getOrSetCorrelationId();

  return null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function protectedLoader({ request, params }: LoaderFunctionArgs) {
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  await genericLoader({ request: request, params: params });
  const user = useAuthenticationStore.getState().user;

  if (!user) {
    const params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    notify("You need to login", true);
    return redirect("/?" + params.toString());
  }
  return null;
}

async function protectedSubscriptionLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const protectedLoad = await protectedLoader({
    request: request,
    params: params,
  });

  if (protectedLoad == null) {
    const user = useAuthenticationStore.getState().user;

    if (user) {
      if (!user.has_active_subscription) {
        notify("You need to have an active subscription.", true);
        return redirect("/profile?" + params.toString());
      }
    } else {
      notify("You need to login", true);
      return redirect("/?" + params.toString());
    }
  }
  return protectedLoad;
}

const router = createBrowserRouter([
  {
    path: "/studio",
    element: <App />,
    loader: protectedSubscriptionLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
    loader: protectedLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
    loader: loginLoader,
  },
  {
    path: "/pricing",
    element: <PricingPage />,
    errorElement: <ErrorPage />,
    loader: genericLoader,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/licenses",
    element: <LegalLicense />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/tos",
    element: <TermsAndConditon />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/thankyou",
    element: <PaymentSuccessPage />,
    errorElement: <ErrorPage />,
    loader: protectedLoader,
  },
]);

const customTheme: CustomFlowbiteTheme = {
  modal: {
    content: {
      inner:
        "relative rounded-lg bg-gray-50 shadow dark:bg-gray-700 flex flex-col max-h-[90vh]",
    },
  },
  sidebar: {
    collapse: {
      label: {
        base: "ml-3 flex-1 whitespace-nowrap truncate text-left",
      },
    },
    item: {
      content: {
        base: "px-3 flex-1 whitespace-nowrap truncate",
      },
    },
  },
};

Sentry.init({
  enabled: SENTRY_ENVIRONMENT == undefined ? false : true,
  environment: SENTRY_ENVIRONMENT,
  dsn: "https://e2647b42c32e4e34e7eb23334146c27a@o4507624564588544.ingest.de.sentry.io/4507639833952336",
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.2, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // setting tracingOrigins to empty will disable distributed tracing.
  // Instead, it will just trace the frontend side of requests.
  // In order to enable distributed tracing, we would need to add stuff to the backend and cloudfront
  tracePropagationTargets: [],
  // tracePropagationTargets: ["localhost", /^https:\/\/api\.djangocompose\.com/, /^https:\/\/staging-api\.djangocompose\.com/],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <>
    <Flowbite theme={{ theme: customTheme }}>
      <div
        className=" bg-white antialiased dark:bg-gray-900 dark:text-white"
        style={{ height: "100vh", overflowX: "scroll" }}
      >
        <RouterProvider
          router={router}
          fallbackElement={<Loader text="Please wait!" />}
        />
      </div>
      <ToastContainer className="text-sm" position="top-right" />
    </Flowbite>
  </>,
  // </React.StrictMode>
);
