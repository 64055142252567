import { zodResolver } from "@hookform/resolvers/zod";
import { Drawer } from "flowbite";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Label, TextInput } from "flowbite-react";
import { HiOutlineDocumentText } from "react-icons/hi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdOutlineDelete, MdCheck } from "react-icons/md";
import { z } from "zod";
import {
  AllRelationFields,
  FieldSchema,
  FieldToSchemaMap,
  RelationFieldToSchemaMap,
} from "../fields/field";
import {
  Action,
  useFlowStore,
  useModalStore,
  useProjectStore,
} from "../model/store";
import {
  RELATIONSHIP_FIELDS,
  drawerGenericOptions,
  notify,
} from "../utils/contants";
import { decideFieldInputType } from "./utils";

const PropertySelected: React.FC = () => {
  const activeModelField = useProjectStore((state) => state.activeModelField)!;
  const selectedFieldToSchemaMap = [
    ...RelationFieldToSchemaMap,
    ...FieldToSchemaMap,
  ].find((value) => value.type == activeModelField.field.type)!;
  const setOpenConfirmBox = useModalStore((state) => state.setOpenConfirmBox);

  const selectedFieldSchema = selectedFieldToSchemaMap.field.schema.omit({
    type: true,
    from: true,
    to: true,
  });
  type SelectedFieldType = z.infer<typeof selectedFieldSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SelectedFieldType>({
    resolver: async (data, context, options) => {
      // you can debug your validation schema here
      return zodResolver(selectedFieldToSchemaMap.field.schemaWithEffect)(
        { ...activeModelField.field, ...data },
        context,
        options,
      );
    },
    defaultValues: selectedFieldSchema.passthrough().parse({
      ...activeModelField.field,
    }),
  });

  const properties = selectedFieldSchema.keyof().options.map((option) => {
    const _def = selectedFieldSchema.shape[option]._def;
    const typeName = _def.typeName;
    return (
      <div className="mb-4" key={option + "key"}>
        {decideFieldInputType({
          typeName: typeName,
          fieldName: option,
          registerHook: register,
          _def: _def,
          errors: errors,
        })}
      </div>
    );
  });

  const activeModelFieldName = activeModelField.name;
  const activeModelFieldType = activeModelField.field.type;

  const setOpenUpsertModelFieldModal = useModalStore(
    (state) => state.setOpenUpsertModelFieldModal,
  );

  const setOpenUpsertRelationModelFieldModal = useModalStore(
    (state) => state.setOpenUpsertRelationModelFieldModal,
  );

  const upsertModelField = useProjectStore((state) => state.upsertModelField);
  const nodes = useFlowStore((state) => state.nodes);

  const onSubmit: SubmitHandler<SelectedFieldType> = (data) => {
    upsertModelField({
      field: FieldSchema.parse({
        ...activeModelField,
        ...{
          field: selectedFieldToSchemaMap.field.schema.parse({
            ...activeModelField.field,
            ...data,
          }),
        },
      }),
      action: Action.EDIT,
    });
    // setActionSuccessful(true);
    // setSelectedField(null);
    // setOpenUpsertModelFieldModal(false)
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-end gap-2">
          <Button size="xs" onClick={handleSubmit(onSubmit)}>
            <MdCheck />
          </Button>
          <Button
            size="xs"
            color="failure"
            onClick={() => {
              setOpenConfirmBox({
                open: true,
                title: "Delete field",
                onConfirm: () => {
                  upsertModelField({
                    field: FieldSchema.parse({
                      ...activeModelField,
                    }),
                    action: Action.DELETE,
                  });
                  notify("Field successfully deleted.");
                },
                onCancel: () => {},
              });
            }}
          >
            <MdOutlineDelete />
          </Button>
        </div>
        <div className="mb-4">
          <div className="mb-1 block">
            <Label htmlFor={activeModelFieldType + "Label"} value="type" />
          </div>
          <TextInput
            sizing="sm"
            type="text"
            value={activeModelFieldType}
            key={activeModelFieldType + "key"}
            readOnly={true}
            onClick={() => {
              if (
                Object.keys(RELATIONSHIP_FIELDS).includes(activeModelFieldType)
              ) {
                setOpenUpsertRelationModelFieldModal(true);
                const _activeModelField =
                  activeModelField.field as unknown as AllRelationFields;
                useProjectStore.getState().setActiveModelConnection({
                  source: nodes.find(
                    (node) => node.id === _activeModelField.from.modelId,
                  )!.data.value,
                  target: nodes.find(
                    (node) => node.id === _activeModelField.to.modelId,
                  )!.data.value,
                });
              } else {
                setOpenUpsertModelFieldModal(true);
              }
            }}
          />
        </div>
        <div className="mb-4">
          <div className="mb-1 block">
            <Label htmlFor={activeModelFieldName + "Label"} value="name" />
          </div>
          <TextInput
            sizing="sm"
            type="text"
            value={activeModelFieldName}
            key={activeModelFieldName + "key"}
            readOnly={true}
            onClick={() => {
              Object.keys(RELATIONSHIP_FIELDS).includes(activeModelFieldType)
                ? setOpenUpsertRelationModelFieldModal(true)
                : setOpenUpsertModelFieldModal(true);
            }}
          />
        </div>
        {/* {errors} */}
        {properties}
        <Button type="submit" className="w-full">
          Save changes
        </Button>
      </form>
    </>
  );
};

function PropertyDrawer() {
  const activeModelField = useProjectStore((state) => state.activeModelField);
  const setActiveModelField = useProjectStore(
    (state) => state.setActiveModelField,
  );
  const setActiveModel = useProjectStore((state) => state.setActiveModel);

  useEffect(() => {
    if (activeModelField !== null) {
      const options = {
        ...drawerGenericOptions,
        onHide: () => {
          setActiveModelField(null);
          setActiveModel(null);
        },
        onShow: () => {},
        onToggle: () => {},
      };
      const drawer = new Drawer(
        document.getElementById("drawer-navigation"),
        options,
      );
      drawer.show();
    }
  }, [activeModelField, setActiveModel, setActiveModelField]);

  const selectedFieldToSchemaMap = [
    ...RelationFieldToSchemaMap,
    ...FieldToSchemaMap,
  ].find(
    (value) => activeModelField && value.type == activeModelField.field.type,
  );

  return (
    <>
      <div
        id="drawer-navigation"
        className="fixed top-0 right-0 z-40 w-80 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white dark:bg-gray-800"
        tabIndex={-1}
        aria-labelledby="drawer-navigation-label"
      >
        <div className="flex items-center justify-between text-base font-semibold text-gray-500 dark:text-gray-400">
          <div>
            <div id="drawer-label" className="flex text-base items-center mt-2">
              <IoMdInformationCircleOutline className="mr-1" />
              <span>Properties</span>
            </div>
          </div>
          {activeModelField && selectedFieldToSchemaMap && (
            <a href={selectedFieldToSchemaMap.field.doc_url} target="_blank">
              <div className="mt-2 text-base flex items-center">
                <HiOutlineDocumentText className="mr-1" /> <span>Docs</span>
              </div>
            </a>
          )}
        </div>

        <hr className="my-3" />
        <div>
          {activeModelField ? (
            <PropertySelected
              key={activeModelField.fieldId}
              // selectedFieldToSchemaMap={selectedFieldToSchemaMap}
              // activeModelField={activeModelField}
            />
          ) : (
            <Label className="text-base" value="No selection" />
          )}
        </div>
      </div>
    </>
  );
}

export { PropertyDrawer };
